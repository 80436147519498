import { deepmerge } from '@mui/utils';
import {
  experimental_extendTheme as extendMuiTheme,
  shouldSkipGeneratingVar as muiShouldSkipGeneratingVar,
} from '@mui/material/styles';
import {
  extendTheme as extendJoyTheme,
  shouldSkipGeneratingVar as joyShouldSkipGeneratingVar,
} from '@mui/joy/styles';
import { Red_Hat_Display, Bitter, Public_Sans, Roboto } from 'next/font/google';
export const redHatDisplay = Red_Hat_Display({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  display: 'swap',
  fallback: ['Helvetica', 'Arial', 'sans-serif'],
  variable: '--font-red-hat-display',
});

export const publicSans = Public_Sans({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  display: 'swap',
  fallback: ['Helvetica', 'Arial', 'sans-serif'],
  variable: '--font-public-sans',
});

export const bitter = Bitter({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  style: ['normal', 'italic'],
  display: 'swap',
  fallback: ['Helvetica', 'Arial', 'sans-serif'],
  variable: '--font-bitter',
});

export const roboto = Roboto({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  display: 'swap',
  fallback: ['Helvetica', 'Arial', 'sans-serif'],
});

const joy_theme_config = {
  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: '#f9e6ef',
          100: '#f2cdde',
          200: '#e59bbd',
          300: '#d9689d',
          400: '#cc367c',
          500: '#bf045b',
          600: '#990349',
          700: '#730237',
          800: '#4c0224',
          900: '#130009',
        },
        // https://maketintsandshades.com/#1f97ac
        tertiary: {
          50: '#e9f5f7', // -90%
          100: '#d2eaee', // -80%
          200: '#a5d5de', // -60%
          300: '#79c1cd', // -40%
          400: '#4cacbd', // -20%
          500: '#1f97ac', // 0%
          600: '#166a78', // 30%
          700: '#104c56', // 50%
          800: '#092d34', // 70%
          900: '#030f11', // 90%
          // Adjust the global variant tokens as you'd like.
          // The tokens should be the same for all color schemes.
          solidBg: 'var(--joy-palette-tertiary-500)',
          solidActiveBg: 'var(--joy-palette-tertiary-600)',
          solidHoverBg: 'var(--joy-palette-tertiary-400)',
          solidColor: 'var(--joy-palette-tertiary-50)',
          outlinedBorder: 'var(--joy-palette-tertiary-500)',
          outlinedColor: 'var(--joy-palette-tertiary-500)',
          outlinedActiveBg: 'var(--joy-palette-tertiary-100)',
          softColor: 'var(--joy-palette-tertiary-900)',
          softBg: 'var(--joy-palette-tertiary-200)',
          softActiveBg: 'var(--joy-palette-tertiary-400)',
          softHoverBg: 'var(--joy-palette-tertiary-300)',
          plainColor: 'var(--joy-palette-tertiary-500)',
          plainActiveBg: 'var(--joy-palette-tertiary-200)',
          plainHoverBg: 'var(--joy-palette-tertiary-100)',
        },
        // https://maketintsandshades.com/#61c554
        success: {
          50: '#eff9ee', // -90%
          100: '#dff3dd', // -80%
          200: '#c0e8bb', // -60%
          300: '#a0dc98', // -40%
          400: '#81d176', // -20%
          500: '#61c554', // 0%
          600: '#448a3b', // 30%
          700: '#31632a', // 50%
          800: '#1d3b19', // 70%
          900: '#0a1408', // 90%
        },
        // https://maketintsandshades.com/#f4bf4f
        warning: {
          50: '#fef9ed', // -90%
          100: '#fdf2dc', // -80%
          200: '#fbe5b9', // -60%
          300: '#f8d995', // -40%
          400: '#f6cc72', // -20%
          500: '#f4bf4f', // 0%
          600: '#ab8637', // 30%
          700: '#7a6028', // 50%
          800: '#493918', // 70%
          900: '#181308', // 90%
        },
        // https://maketintsandshades.com/#cf4743
        danger: {
          50: '#faedec', // -90%
          100: '#f5dad9', // -80%
          200: '#ecb5b4', // -60%
          300: '#e2918e', // -40%
          400: '#d96c69', // -20%
          500: '#cf4743', // 0%
          600: '#91322f', // 30%
          700: '#682422', // 50%
          800: '#3e1514', // 70%
          900: '#150707', // 90%
        },
        // https://maketintsandshades.com/#1f97ac
        info: {
          50: '#e9f5f7', // -90%
          100: '#d2eaee', // -80%
          200: '#a5d5de', // -60%
          300: '#79c1cd', // -40%
          400: '#4cacbd', // -20%
          500: '#1f97ac', // 0%
          600: '#166a78', // 30%
          700: '#104c56', // 50%
          800: '#092d34', // 70%
          900: '#030f11', // 90%
        },
        background: {
          body: 'var(--joy-palette-neutral-50)',
          white: 'var(--joy-palette-common-white)',
        },
        gradient: {
          background:
            'linear-gradient(to bottom, rgba(255,255,255,0), var(--joy-palette-background-surface))',
        },
      },
    },
    dark: {
      palette: {
        primary: {
          50: '#f9e6ef',
          100: '#f2cdde',
          200: '#e59bbd',
          300: '#d9689d',
          400: '#cc367c',
          500: '#bf045b',
          600: '#990349',
          700: '#730237',
          800: '#4c0224',
          900: '#260112',
        },
        background: {
          body: 'var(--joy-palette-common-black)',
          white: 'var(--joy-palette-common-black)',
          surface: '#0d0912',
        },
        // https://maketintsandshades.com/#1f97ac
        tertiary: {
          50: '#e9f5f7', // -90%
          100: '#d2eaee', // -80%
          200: '#a5d5de', // -60%
          300: '#79c1cd', // -40%
          400: '#4cacbd', // -20%
          500: '#1f97ac', // 0%
          600: '#166a78', // 30%
          700: '#104c56', // 50%
          800: '#092d34', // 70%
          900: '#030f11', // 90%
          // Adjust the global variant tokens as you'd like.
          // The tokens should be the same for all color schemes.
          solidBg: 'var(--joy-palette-tertiary-500)',
          solidActiveBg: 'var(--joy-palette-tertiary-600)',
          solidHoverBg: 'var(--joy-palette-tertiary-400)',
          solidColor: 'var(--joy-palette-tertiary-50)',
          outlinedBorder: 'var(--joy-palette-tertiary-500)',
          outlinedColor: 'var(--joy-palette-tertiary-500)',
          outlinedActiveBg: 'var(--joy-palette-tertiary-100)',
          softColor: 'var(--joy-palette-tertiary-900)',
          softBg: 'var(--joy-palette-tertiary-200)',
          softActiveBg: 'var(--joy-palette-tertiary-400)',
          softHoverBg: 'var(--joy-palette-tertiary-300)',
          plainColor: 'var(--joy-palette-tertiary-500)',
          plainActiveBg: 'var(--joy-palette-tertiary-200)',
          plainHoverBg: 'var(--joy-palette-tertiary-100)',
        },
        // https://maketintsandshades.com/#61c554
        success: {
          50: '#eff9ee', // -90%
          100: '#dff3dd', // -80%
          200: '#c0e8bb', // -60%
          300: '#a0dc98', // -40%
          400: '#81d176', // -20%
          500: '#61c554', // 0%
          600: '#448a3b', // 30%
          700: '#31632a', // 50%
          800: '#1d3b19', // 70%
          900: '#0a1408', // 90%
        },
        // https://maketintsandshades.com/#f4bf4f
        warning: {
          50: '#fef9ed', // -90%
          100: '#fdf2dc', // -80%
          200: '#fbe5b9', // -60%
          300: '#f8d995', // -40%
          400: '#f6cc72', // -20%
          500: '#f4bf4f', // 0%
          600: '#ab8637', // 30%
          700: '#7a6028', // 50%
          800: '#493918', // 70%
          900: '#181308', // 90%
        },
        // https://maketintsandshades.com/#cf4743
        danger: {
          50: '#faedec', // -90%
          100: '#f5dad9', // -80%
          200: '#ecb5b4', // -60%
          300: '#e2918e', // -40%
          400: '#d96c69', // -20%
          500: '#cf4743', // 0%
          600: '#91322f', // 30%
          700: '#682422', // 50%
          800: '#3e1514', // 70%
          900: '#150707', // 90%
        },
        // https://maketintsandshades.com/#1f97ac
        info: {
          50: '#e9f5f7', // -90%
          100: '#d2eaee', // -80%
          200: '#a5d5de', // -60%
          300: '#79c1cd', // -40%
          400: '#4cacbd', // -20%
          500: '#1f97ac', // 0%
          600: '#166a78', // 30%
          700: '#104c56', // 50%
          800: '#092d34', // 70%
          900: '#030f11', // 90%
        },
        gradient: {
          background:
            'linear-gradient(to bottom, rgba(255,255,255,0), var(--joy-palette-background-surface))',
        },
      },
    },
  },
  fontFamily: {
    display: `${redHatDisplay.style.fontFamily}, var(--joy-fontFamily-fallback)`,
    body: `${publicSans.style.fontFamily}, var(--joy-fontFamily-fallback)`,
  },
  typography: {
    body: {
      fontFamily: publicSans.style.fontFamily,
    },
    display1: {
      fontSize: '5rem',
      fontFamily: redHatDisplay.style.fontFamily,
      fontWeight: 'bold',
      lineHeight: '6.5rem',
    },
    display2: {
      fontSize: '3rem',
      fontFamily: redHatDisplay.style.fontFamily,
      fontWeight: 'bold',
      lineHeight: '4rem',
    },
    h1: {
      fontSize: '6rem',
      fontFamily: redHatDisplay.style.fontFamily,
      fontWeight: '700',
      lineHeight: '6.5rem',
      letterSpacing: '0.025rem',
      wordWrap: 'break-word',
    },
    h2: {
      fontSize: '4rem',
      fontFamily: redHatDisplay.style.fontFamily,
      fontWeight: '700',
      lineHeight: '4rem',
      letterSpacing: '0.025rem',
      wordWrap: 'break-word',
    },
    h3: {
      fontSize: '3rem',
      fontFamily: redHatDisplay.style.fontFamily,
      fontWeight: '700',
      lineHeight: '3.5rem',
      letterSpacing: '0.025rem',
      wordWrap: 'break-word',
    },
    h4: {
      fontSize: '2.25rem',
      fontFamily: redHatDisplay.style.fontFamily,
      fontWeight: '700',
      lineHeight: '2.5rem',
      letterSpacing: '0.025rem',
      wordWrap: 'break-word',
    },
    h5: {
      fontSize: '1.5rem',
      fontFamily: redHatDisplay.style.fontFamily,
      fontWeight: '700',
      lineHeight: '1.5rem',
      letterSpacing: '0.025rem',
      wordWrap: 'break-word',
    },
    h6: {
      fontSize: '1.25rem',
      fontFamily: redHatDisplay.style.fontFamily,
      fontWeight: '700',
      lineHeight: '1.25rem',
      letterSpacing: '0.025rem',
      wordWrap: 'break-word',
    },
    'title-lg': {
      fontSize: '1.25rem',
      fontFamily: publicSans.style.fontFamily,
      fontWeight: '700',
      textTransform: 'uppercase',
      lineHeight: '1.5rem',
      letterSpacing: '0.025rem',
      wordWrap: 'break-word',
    },
    'title-md': {
      fontSize: '1.25rem',
      fontFamily: publicSans.style.fontFamily,
      fontWeight: '700',
      lineHeight: '1.5rem',
      letterSpacing: '0.0125rem',
      wordWrap: 'break-word',
    },
    'title-sm': {
      fontSize: '1rem',
      fontFamily: publicSans.style.fontFamily,
      fontWeight: '400',
      lineHeight: '1.25rem',
      wordWrap: 'break-word',
    },
    'title-xs': {
      fontSize: '1rem',
      fontFamily: publicSans.style.fontFamily,
      fontWeight: '700',
      textTransform: 'uppercase',
      lineHeight: '1.25rem',
      letterSpacing: 0.6,
      wordWrap: 'break-word',
    },
    'body-lg': {
      fontSize: '1.25rem',
      fontFamily: publicSans.style.fontFamily,
      fontWeight: '400',
      lineHeight: '1.5rem',
      wordWrap: 'break-word',
    },
    'body-md': {
      fontSize: '1rem',
      fontFamily: publicSans.style.fontFamily,
      fontWeight: '400',
      lineHeight: '1.5rem',
      wordWrap: 'break-word',
    },
    'body-sm': {
      fontSize: '0.875rem',
      fontFamily: publicSans.style.fontFamily,
      fontWeight: '400',
      lineHeight: '1.25rem',
      wordWrap: 'break-word',
    },
    'body-xs': {
      fontSize: '0.57rem',
      fontFamily: publicSans.style.fontFamily,
      fontWeight: '500',
      lineHeight: '1rem',
      wordWrap: 'break-word',
    },
  },
  components: {
    JoyIconButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.size === 'xs' && {
            '--Icon-fontSize': '.2rem',
            '--Button-gap': '0.25rem',
            minHeight: 'var(--Button-minHeight, 1.25rem)',
            fontSize: theme.vars.fontSize.sm,
            paddingBlock: '2px',
            paddingInline: '0.25rem',
          }),
          ...(ownerState.size === 'xl' && {
            '--Icon-fontSize': '2rem',
            '--Button-gap': '1rem',
            minHeight: 'var(--Button-minHeight, 4rem)',
            fontSize: theme.vars.fontSize.xl,
            paddingBlock: '0.5rem',
            paddingInline: '2rem',
          }),
        }),
      },
    },
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          textTransform: 'uppercase',
          fontFamily: roboto.style.fontFamily,
          ...(ownerState.size === 'xs' && {
            '--Icon-fontSize': '.2rem',
            '--Button-gap': '0.25rem',
            minHeight: 'var(--Button-minHeight, 1.75rem)',
            fontSize: theme.vars.fontSize.sm,
            paddingBlock: '2px',
            paddingInline: '0.5rem',
          }),
          ...(ownerState.size === 'medium' && {
            paddingBlock: '0.375rem',
            paddingInline: '1rem',
          }),
          ...(ownerState.size === 'large' && {
            paddingBlock: '0.5rem',
            paddingInline: '1.375rem',
          }),
          ...(ownerState.size === 'xl' && {
            '--Icon-fontSize': '2rem',
            '--Button-gap': '1rem',
            minHeight: 'var(--Button-minHeight, 4rem)',
            fontSize: theme.vars.fontSize.xl,
            paddingBlock: '0.5rem',
            paddingInline: '2rem',
          }),
          ...(ownerState.variant === 'outlined' &&
            {
              // border: '1px solid',
            }),
        }),
      },
    },
    JoySelect: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.size === 'xs' && {
            '--Icon-fontSize': '.2rem',
            '--Button-gap': '0.25rem',
            minHeight: 'var(--Button-minHeight, 1.75rem)',
            fontSize: theme.vars.fontSize.xs,
            paddingBlock: '2px',
            paddingInline: '0.25rem',
          }),
          ...(ownerState.size === 'xl' && {
            '--Icon-fontSize': '2rem',
            '--Button-gap': '1rem',
            minHeight: 'var(--Button-minHeight, 4rem)',
            fontSize: theme.vars.fontSize.xl,
            paddingBlock: '0.5rem',
            paddingInline: '2rem',
          }),
        }),
      },
    },
  },
};

// Create a theme instance.
const { unstable_sxConfig: joySxConfig, ...joyTheme } = extendJoyTheme(joy_theme_config);

const { unstable_sxConfig: muiSxConfig, ...muiTheme } = extendMuiTheme({
  // This is required to point to `var(--joy-*)` because we are using
  // `CssVarsProvider` from Joy UI.
  cssVarPrefix: 'joy',
  colorSchemes: joy_theme_config.colorSchemes,
});

// joyTheme will deeply merge to muiTheme.
const mergedTheme = deepmerge(muiTheme, joyTheme);

// remove dark mode
mergedTheme.palette.mode = 'light';
mergedTheme.colorSchemes = {
  light: mergedTheme.colorSchemes.light,
};

mergedTheme.unstable_sxConfig = {
  ...muiSxConfig,
  ...joySxConfig,
};

export default mergedTheme;

export const extendedJoyTheme = extendJoyTheme(joy_theme_config);

export const shouldSkipGeneratingVar = (keys) => {
  muiShouldSkipGeneratingVar(keys) || joyShouldSkipGeneratingVar(keys);
};
